<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <CCardHeader>
        <CRow>
        <CCol col="6" class="styleHeader">{{ $t('message.showCustomer') }}  </CCol>
          <CCol col="6">
         <CButton style=" float: left;" color="btn btn-primary"  @click="goPrint">{{$t('message.printInvoice')}}  </CButton>
         </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <div class="row "  >
            <div class="col-md-6" >
                <table class="table table-bordered"  >
                  <tr>
                    <th>{{ $t('message.customerName') }}</th>
                    <td>{{ customer.name }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.customerPhone') }}</th>
                    <td>{{ customer.phone }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.customerAddress') }}</th>
                    <td>{{ customer.address }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.typeOfCustomer') }}</th>
                   <td  v-if="customer.type_of_customer = 1">{{ $t('message.distributor') }}</td>
                    <td  v-else-if="customer.type_of_customer = 2">{{ $t('message.sellingPoint') }}</td>
                    <td  v-else-if="customer.type_of_customer = 3">{{ $t('message.branchOffice') }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.totalOrder') }}</th>
                    <td>{{totalOrderCustomer }}</td>
                  </tr>
                   <tr>
                    <th> {{ $t('message.invoiceTotal') }}</th>
                    <td>{{totalInvoicesCustomer}}</td>
                  </tr>
                   <tr>
                    <th> {{ $t('message.customerBalance') }}</th>
                    <td>{{balance}}</td>
                  </tr>
                </table>
            </div>
            <div class="col-md-6">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.zain') }}</th>
                    <td>{{ customer.zain_transfer_no }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.mtn') }}</th>
                    <td>{{ customer.mtn_transfer_no }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.digital') }}</th>
                    <td>{{ customer.digitel_transfer_no }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.mgurush') }}</th>
                    <td>{{ customer.mgurush_transfer_no }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.momo') }}</th>
                    <td>{{ customer.momo_transfer_no }}</td>
                  </tr>

                </table>
            </div>
        </div>
         <div class="row" v-if="transactions">
             <CCol col="6" class="styleHeader">{{ $t('message.customerTransaction') }}  </CCol>
            <div class="col-md-10 m-auto">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.date') }}   </th>
                    <th> {{ $t('message.credit') }}  </th>
                      <th>  {{ $t('message.debit') }}  </th>
                      <th>  {{ $t('message.balance') }}  </th>
                  </tr>
                   <tr v-for=" transaction in transactions" :key="transaction.id">
                    <td>{{ transaction.date }}</td>
                    <td>{{ transaction.paid_from_customer | number}}</td>
                    <td>{{ transaction.needed_from_customer | number}}</td>
                    <td>{{ transaction.balance | number }}</td>
                  </tr>
                </table>
            </div>
         </div>
         <div class="row" v-if="customer.orders[0]">
             <CCol col="6" class="styleHeader">{{ $t('message.customerOrders') }}  </CCol>
            <div class="col-md-10 m-auto">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.orderNo') }}   </th>
                    <th> {{ $t('message.totalOrder') }}  </th>
                      <th>  {{ $t('message.orderStatus') }}  </th>
                      <th>  {{ $t('message.orderCreatedAt') }}  </th>
                       <th>  {{ $t('message.total') }}  </th>
                       <div class="totalpayments">{{totalOrderCustomer }}</div>
                  </tr>
                   <tr v-for=" order in customer.orders" :key="order.id">
                    <td id='details' @click='orderDetail(order.id)'>{{ order.order_no }}</td>
                    <td>{{ order.total | number}}</td>
                    <td v-if="order.order_status_id === 1">{{ $t('message.pending') }}</td>
                    <td v-else-if="order.order_status_id === 2">{{ $t('message.approve') }}</td>
                    <td>{{order.created_at }}</td>
                  </tr>
                </table>
            </div>
         </div>
            <div class="row" v-if="customer.sales_invoices[0]">
             <CCol col="6" class="styleHeader">{{ $t('message.customerInvoices') }}  </CCol>
            <div class="col-md-10 m-auto">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.invoicesId') }}   </th>
                     <th>  {{ $t('message.pricePaid') }}  </th>
                      <th>  {{ $t('message.CreatedAt') }}  </th>
                       <th>  {{ $t('message.total') }}  </th>
                       <div class="totalpayments">{{totalInvoicesCustomer}}</div>
                  </tr>
                   <tr v-for=" invoice in customer.sales_invoices" :key="invoice.id">
                    <td id='details' @click='paymentDetail(invoice.id)'>{{ invoice.id }}</td>
                     <td>{{ invoice.price_paid | number}}</td>
                    <td>{{ invoice.created_at  }}</td>
                  </tr>

                </table>
            </div>
        </div>
        <CButton color="btn btn-primary"  @click="goBack">{{ $t('message.back') }}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>
<script>
import moment from 'moment'

export default {
  name: 'ShowCustomer',
  data () {
    return {
      customer: [],
      totalOrderCustomer: {},
      totalInvoicesCustomer: {},
      balance: '',
      transactions: [],
      moment: moment
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}customers/${this.$route.params.id}`)
      .then((response) => {
        this.customer = response.data.data.customer
        this.totalOrderCustomer = response.data.data.totalOrderCustomer
        this.totalInvoicesCustomer = response.data.data.totalInvoicesCustomer
        this.balance = response.data.data.balance
        this.transactions = response.data.data.transactions
      })
  },
  methods: {
    orderDetail (id) {
      this.$router.push({ path: '/orders/' + id + '/show' })
    },
    paymentDetail (id) {
      this.$router.push({ path: '/payments/' + id + '/show' })
    },
    goPrint () {
      this.$router.push({ path: '/customers/' + this.$route.params.id + '/print' })
    },

    goBack () {
      this.citiesOpened ? this.$router.go(-1) : this.$router.push({ path: '/customers' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
.العربية .totalpayments{
      position: absolute;
    top: 66px;
    left: 78px;
}
.English .totalpayments{
    right: 32px;
   position: absolute;
       top: 69px;
}
#details{
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
</style>
